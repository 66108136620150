






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true
        },
        content: {
          name: "Quản lý nền tảng MKT",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true
            },
            code: {
              text: "Mã",
              sortable: true
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime"
              }
            },
            action: {}
          },
          template: {
            itemActionButtons: {
              edit: {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name"
                    }
                  }
                }
              },
              delete: {}
            }
          }
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "mktPlatforms",
                "findAll",
                options
              );
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call(
                "mktPlatforms",
                "create",
                undefined,
                item
              );
            },
            async update(item) {
              return await coreApiClient.call(
                "mktPlatforms",
                "update",
                {
                  id: item._id
                },
                {
                  data: item
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("mktPlatforms", "delete", {
                id: item._id
              });
            }
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true
                }
              },
              code: {
                attrs: {
                  label: "Mã",
                  required: true
                }
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả"
                }
              }
            },
            insertForm: {
              content: {
                fieldNames: ["name", "code", "description"]
              }
            },
            editForm: {
              content: {
                fieldNames: ["name", "code", "description"]
              }
            },
            delete: {
              confirmDisplayField: "name"
            }
          }
        }
      }
    };
  }
});
